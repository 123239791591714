<template>
  <v-row justify="center">
    
      <v-card>
        <v-card-title>
          <span class="text-h5">{{isEditing?'Editar Pago':'Agregar Pago'}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="Cantidad*"
                  required
                  v-model="pay.quantity"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="Periodo*"  
                  required
                  v-model="pay.period"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="pay.date"
                            label="Fecha"
                            hint="Selecione la fecha de abono"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker 
                        v-model="pay.date"
                        @input="menu = false"
                        ></v-date-picker>
                </v-menu>
              
              </v-col>
              <v-col cols="12">
                  <v-select 
                    :items="payTypesList"  
                    item-text="name"
                    item-value="id" 
                    label="Tipo de Pago" 
                    v-model="pay.paymenttypeid"
                    required
                    >
                       
                    </v-select>
              </v-col>
              <v-col
                cols="12"
              >
               <v-select 
                    :items="packList" 
                    item-text="name"
                    item-value="id"
                    label="Paquete*" 
                    v-model="pay.pack"
                    required
                    ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>* Indicador de Requeridos</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="savePay"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    
  </v-row>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    props:{
        payTypesList: {
            type:Array
            ,default: () =>  [{id:1,name:"Efectivo",code:"ca"},
                     {id:2,name:"Tarjeta Credito",code:"tc"},
                     {id:3,name:"Tarjeta Debito",code:"td"},
                     {id:4,name:"Transferencia",code:"tf"},
                     {id:5,name:"En Linea",code:"on"}]
        }
        ,packList:{
            type:Array
            ,default:()=>[{id:1,name:"Normal",code:"n"},
                  {id:2,name:"Completo",code:"f"}]
        }
        ,deviceId:{
            type: Number,
            default: () => 0
        }
        ,editItem:{
            type: Object,
            default:()=> {}
        }
    },
    data: ()=>({
        menu:false,
        pay:{}
    }),
    mounted(){
        
        if(!this.isEditing)
            this.pay = Object.assign({},{
                    quantity:0,
                    period:0,
                    date:null,
                    paymenttypeid:0,
                    pack:0,
                    deviceId:0});
        else
            this.pay = Object.assign({},this.editItem);
        
        this.pay.deviceId = this.deviceId;
    },
    computed:{
        isEditing(){
            return this.editItem.id !== undefined;
        }
    },
    watch:{
        editItem(newVal){
                this.pay = Object.assign({},newVal);
                this.pay.deviceId = this.deviceId;
        }
    },
    methods:{
        ...mapActions(['addPayment','updatePayment','showSnackbar']),
        savePay(){
            this.pay.period = parseInt(this.pay.period);
            this.pay.quantity = parseInt(this.pay.quantity);

            if(this.isEditing)
                this.updatePayment(this.pay).then((res)=>{
                    //console.log(res);
                    if(res.status == 200){
                        this.showSnackbar({
                            showing:true,
                            message: `Payment saved ${res.data.data.id}!`,
                            color:'success'
                        });

                        this.$emit('update');
                    }
                    
                }).catch((err)=>{
                    console.log(err);
                });
            else
                this.addPayment(this.pay).then((res)=>{
                    //console.log(res);
                    if(res.status == 200 || res.status == 201){
                        this.showSnackbar({
                            showing:true,
                            message: `Payment created ${res.data.data.id}!`,
                            color:'success'
                        });

                        this.$emit('update');
                    }
                    
                }).catch((err)=>{
                    console.log(err);
                });
            
        },
        close(){
            this.$emit('close');
        }
    }
}
</script>

<style>

</style>